import React, {
  FC,
  forwardRef,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { InFocusGuard } from 'react-focus-on'
import { useTranslation } from 'react-i18next'
import { AnimatePresence } from 'framer-motion'
import styled from 'styled-components'

import { colors } from '../../constants/colors'
import { ProgressButtonProps } from '../../elements/ProgressButton/ProgressButton'
import { Type } from '../../elements/Typography/Typography'
import { IPricesFields } from '../../generated/contentful'
import { useBreakpoints } from '../../hooks/useBreakpoints'
import { theme } from '../../styles/theme'
import { BookingEngine, DeepPartial } from '../../styles/types'
import { Drawer } from '../../units/Drawer'
import { BookingBarItem, BookingBarItemProps } from './BookingBarItem'
import { BookingBarPanel, BookingBarPanelProps } from './BookingBarPanel'
import * as styles from './styles'

export enum PanelPosition {
  Above = 'above',
  Below = 'below',
}

export enum MobilePosition {
  Inline = 'inline',
  FixedToBottom = 'fixedToBottom',
}

interface BookingBarProps {
  button?: ReactElement<{
    children: ReactNode
    onClick: () => void
    style: React.CSSProperties
  }>
  price?: IPricesFields
  details?: ReactNode
  children?: ReactNode
  columnTemplate?: string
  panelPosition?: PanelPosition
  mobilePosition?: MobilePosition
  // if switchBetweenPanels is true, the bottom button in mobile view panel
  // goes to next step instead of closing the drawer
  switchBetweenPanels?: boolean
  mobileBarAsButton?: boolean
  themeStyle?: DeepPartial<BookingEngine>
}

interface BookingBarComposition {
  Item?: FC<BookingBarItemProps>
  Panel?: FC<BookingBarPanelProps>
  BookingBarButton?: FC<ProgressButtonProps>
}

const BookingBar: React.ForwardRefExoticComponent<
  BookingBarProps & React.RefAttributes<any>
> &
  BookingBarComposition = forwardRef(
  (
    {
      children,
      button,
      columnTemplate,
      panelPosition = PanelPosition.Above,
      switchBetweenPanels,
      mobileBarAsButton,
      themeStyle,
    },
    ref
  ) => {
    const { t } = useTranslation()
    const buttonRef = useRef()
    const { isMobile } = useBreakpoints()
    const [selectedIndex, setSelectedIndex] = useState(-1)
    const [showDrawer, setShowDrawer] = useState(false)

    const panels = React.Children.toArray(children).filter(child => {
      const item = child as ReactElement<
        PropsWithChildren<BookingBarPanelProps>
      >
      return item.type === BookingBarPanel
    }) as ReactElement<PropsWithChildren<BookingBarPanelProps>>[]

    const items = React.Children.toArray(children).filter(child => {
      const item = child as ReactElement<PropsWithChildren<BookingBarItemProps>>
      return item.type === BookingBarItem
    }) as ReactElement<PropsWithChildren<BookingBarItemProps>>[]

    const mobileItems = items.reduce((acc, cur) => {
      if (cur.props.id !== 'departureDate') {
        if (cur.props.id === 'arrivalDate') {
          acc.push({ ...cur.props, ['id']: 'datePicker' })
        } else {
          acc.push(cur.props)
        }
      }
      return acc
    }, [])

    const tabRefs = mobileItems?.map(() => useRef(null))

    useImperativeHandle(ref, () => ({
      focusIndex: (index: number) => {
        tabRefs[index]?.current?.focus()
      },
      onClose: () => {
        setSelectedIndex(-1)
      },
    }))

    const handleClickOutside = () => {
      setSelectedIndex(-1)
    }

    const onSelected = (index: number) => {
      setSelectedIndex(index)
    }

    const onOpenDrawer = () => {
      if (selectedIndex < 0) {
        setSelectedIndex(0)
      }

      setShowDrawer(true)
    }

    const onCloseDrawer = () => {
      setSelectedIndex(-1)
      setShowDrawer(false)
    }

    const buttonOnClick = () => {
      if (!React.isValidElement(button)) return

      if (showDrawer) {
        onCloseDrawer()
        setSelectedIndex(-1)
      }

      button.props.onClick?.()
    }

    const onNextPanel = () => {
      let nextIndex = selectedIndex + 1
      const currentItem = items[selectedIndex]
      const nextItem = items[nextIndex]

      if (
        React.isValidElement(currentItem) &&
        React.isValidElement(nextItem) &&
        nextItem.props.panelId === currentItem.props.panelId
      ) {
        nextIndex++

        if (nextIndex >= tabRefs.length - 1) {
          return buttonOnClick()
        }
      }
      setSelectedIndex(nextIndex)
    }
    const onPrevPanel = () => {
      let nextIndex = selectedIndex - 1
      const currentItem = items[selectedIndex]
      const nextItem = items[nextIndex]

      if (
        React.isValidElement(currentItem) &&
        React.isValidElement(nextItem) &&
        nextItem.props.panelId === currentItem.props.panelId
      ) {
        nextIndex--
      }

      if (nextIndex >= 0) {
        setSelectedIndex(nextIndex)
      }
    }

    const handleKeyPress = event => {
      if (event.key === 'ArrowLeft') {
        focusPreviousTab()
      }
      if (event.key === 'ArrowRight') {
        focusNextTab()
      }
      if (event.key === 'Escape') {
        handleClickOutside()
      }
    }

    const focusPreviousTab = () => {
      const next =
        selectedIndex - 1 < 0 ? tabRefs.length - 1 : selectedIndex - 1
      setSelectedIndex(next)
      tabRefs[next]?.current?.focus()
    }

    const focusNextTab = () => {
      const next =
        selectedIndex + 1 > tabRefs.length - 1 ? 0 : selectedIndex + 1
      setSelectedIndex(next)
      tabRefs[next]?.current?.focus()
    }

    const MobileButton =
      React.isValidElement(button) &&
      React.cloneElement(button, {
        style: { width: '100%', height: '100%' },
        onClick: onOpenDrawer,
        children: button.props.children,
      })

    const MobileBarButton =
      React.isValidElement(button) &&
      React.cloneElement(button, {
        style: { width: '100%' },
        onClick:
          switchBetweenPanels && selectedIndex < tabRefs.length - 1
            ? onNextPanel
            : buttonOnClick,
        children:
          switchBetweenPanels && selectedIndex < tabRefs.length - 1
            ? t('Continue')
            : t('Check availability'),
      })

    const MobileBarWrapper = styled.div({
      display: 'flex',
      alignItems: 'center',
      border: `1px solid ${colors.formGrey}`,
      boxShadow: '0px 0px 30px 6px rgba(0, 0, 0, 0.1)',
      backgroundColor: colors.white,

      button: {
        flexGrow: 1,
        borderRight: `1px solid ${colors.formGrey}`,
        textAlign: 'center',
        paddingTop: theme.spacing[2.5],
        paddingBottom: theme.spacing[2.5],
        cursor: 'pointer',

        ':last-child': {
          borderRight: 'none',
        },
      },
    })

    const onMobileBarItemClick = index => {
      setShowDrawer(true)
      setSelectedIndex(index)
    }

    if (isMobile) {
      return (
        <>
          {mobileBarAsButton ? (
            <styles.MobileBar>
              <InFocusGuard>{MobileButton}</InFocusGuard>
            </styles.MobileBar>
          ) : (
            <MobileBarWrapper>
              {mobileItems.map((item, index) => (
                <button
                  key={item.id}
                  onClick={() => {
                    if (
                      panels.some(panel => panel?.props?.id === item?.panelId)
                    ) {
                      onMobileBarItemClick(index)
                    }
                  }}
                >
                  <Type preset="text" color={colors.midGrey}>
                    {item.mobileValue ?? item.value}
                  </Type>
                </button>
              ))}
            </MobileBarWrapper>
          )}

          <Drawer
            show={showDrawer}
            onDismiss={onCloseDrawer}
            offset={80}
            fullHeight
            onBack={
              selectedIndex > 0 && switchBetweenPanels
                ? onPrevPanel
                : onCloseDrawer
            }
            shards={[buttonRef]}
            withHeader
            themeStyle={themeStyle}
          >
            <>
              {React.isValidElement(panels[selectedIndex]) &&
                React.cloneElement(panels[selectedIndex], {
                  selected: true,
                })}
            </>
            <styles.FixedMobileBar
              ref={buttonRef}
              visible={showDrawer}
              $themeStyle={themeStyle?.spaBookingBar}
            >
              <InFocusGuard>{MobileBarButton}</InFocusGuard>
            </styles.FixedMobileBar>
          </Drawer>
        </>
      )
    }

    return (
      <styles.Bar>
        <styles.List
          role="tablist"
          aria-label="Booking bar"
          aria-orientation="horizontal"
          onKeyDown={handleKeyPress}
          columnCount={items.length}
          columnTemplate={columnTemplate}
        >
          {items.map((child, index) => {
            const props = {
              index,
              selected: index === selectedIndex,
              onSelected,
              tabRef: tabRefs[index],
            }
            return React.isValidElement(child)
              ? React.cloneElement(child, props)
              : null
          })}
          {button}
        </styles.List>
        <styles.Portal panelPosition={panelPosition}>
          <styles.PortalGrid
            columnCount={items.length}
            columnTemplate={columnTemplate}
          >
            <AnimatePresence>
              {panels.map(panel => {
                if (!React.isValidElement(panel)) return

                const itemsForPanel = items.filter(item => {
                  if (!React.isValidElement(item)) return

                  return item.props.panelId === panel.props.id
                })

                const indexes = itemsForPanel.map(x => items.indexOf(x))
                return React.cloneElement(panel, {
                  selected: indexes.includes(selectedIndex),
                  index: indexes[0] + 1,
                  columnCount: items.length + 1,
                  onClickOutside: handleClickOutside,
                })
              })}
            </AnimatePresence>
          </styles.PortalGrid>
        </styles.Portal>
      </styles.Bar>
    )
  }
)

BookingBar.Item = BookingBarItem
BookingBar.Panel = BookingBarPanel
BookingBar.displayName = 'BookingBar'

export { BookingBar }
