import React from 'react'
import styled from 'styled-components'

import { colors } from '../../constants/colors'
import { durations } from '../../constants/durations'
import { zIndex as zIndexUtil } from '../../constants/zIndex'
import { Link } from '../Link'

type TransparentFabProps = { zIndex: number }

const TransparentFab = styled.button<TransparentFabProps>`
  background: transparent;
  border-radius: 50%;
  border: 0;
  cursor: pointer;
  display: flex;
  height: 47px;
  user-select: none;
  width: 47px;
  z-index: ${({ zIndex }) => (zIndex ? zIndex : zIndexUtil.aboveMenu)};
`

const Line = styled.div`
  background: ${colors.white};
  height: 2px;
  margin: auto;
  width: 18px;
`

const TopLine = styled(Line)`
  transform: translateY(1px) rotateZ(-45deg);
`

const BottomLine = styled(Line)`
  transform: translateY(-1px) rotateZ(45deg);
`

const Icon = styled.div`
  margin: auto;
  overflow: visible;
  transform: rotateZ(-90deg);
  transition: transform ${durations.short}ms ease-out;

  ${TransparentFab}:hover & {
    transform: none;
  }
`

type CloseFabProps = {
  to?: unknown
  onClick: () => void
  zIndex?: string | number
}

export const TransparentCloseFab = ({ to, onClick, zIndex }: CloseFabProps) => (
  <TransparentFab
    as={to ? Link : 'button'}
    to={to}
    onClick={onClick}
    zIndex={zIndex}
    aria-label="Close Navigation"
    background="transparent"
  >
    <Icon>
      <TopLine />
      <BottomLine />
    </Icon>
  </TransparentFab>
)
