import { intervalToDuration } from 'date-fns/intervalToDuration'

export const getMinAndMaxMonthsShown = (
  monthsShown: number,
  openToDate?: Date,
  endDate?: Date,
  maxDate?: Date
) => {
  const safeOpenToDate = openToDate ?? new Date()
  safeOpenToDate.setDate(1)
  // This is to check if selected endDate is in view, if not, show all months up to endDate in view
  // So we higher the min value to make sure the endDate is in view
  const minDuration = intervalToDuration({
    start: safeOpenToDate,
    end: endDate || new Date(),
  })
  const minMonthsToRenderSelectedDates =
    minDuration.years * 12 + minDuration.months + 1
  const minMonthsShown = Math.max(monthsShown, minMonthsToRenderSelectedDates)

  // This is to check if maxDate is in view, if not, show all months up to maxDate in view
  const maxDuration = intervalToDuration({
    start: safeOpenToDate,
    end: maxDate || new Date(),
  })

  const maxMonthsShown = maxDuration.years * 12 + maxDuration.months + 1

  return { minMonthsShown, maxMonthsShown }
}
