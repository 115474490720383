import React, { useEffect } from 'react'
import { Asset } from 'contentful'
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  DotGroup,
  Slide,
  Slider,
} from 'pure-react-carousel'
import styled, { css } from 'styled-components'

import { breakpoints } from '../constants/breakpoints'
import { colors } from '../constants/colors'
import { zIndex } from '../constants/zIndex'
import { ContentfulImage } from '../elements/ContentfulImage'
import { TransparentCloseFab } from '../elements/Fab/TransparentCloseFab'
import { media } from '../utils/media'
import { ClientOnlyModal as Modal } from './ClientOnlyModal'
import { VisuallyHidden } from './VisuallyHidden'

type CarouselModalProps = {
  show: boolean
  onHide: () => void
  carousel: Asset[]
  currentSlide?: number
  sliderId?: string
}

const LeftButton = styled(ButtonBack)`
  z-index: ${zIndex.newLayer * 10};

  height: ${({ theme }) => theme.spacing[6]};
  width: ${({ theme }) => theme.spacing[6]};

  background: transparent;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='14' fill='none'%3E%3Cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M7 13L1 7l6-6'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
  ${media.md(css`
    left: ${({ theme }) => theme.spacing[1]};
  `)}

  @media (max-width: ${breakpoints.md}px) and (orientation: landscape) {
    left: 8px;
  }
`

const RightButton = styled(ButtonNext)`
  z-index: ${zIndex.newLayer * 10};

  height: ${({ theme }) => theme.spacing[6]};
  width: ${({ theme }) => theme.spacing[6]};

  background: transparent;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='14' fill='none'%3E%3Cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M1 1l6 6-6 6'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  ${media.md(css`
    right: ${({ theme }) => theme.spacing[1]};
  `)}

  @media (max-width: ${breakpoints.md}px) and (orientation: landscape) {
    right: 8px;
  }
`

const Bars = styled(DotGroup)`
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-left: 24px;
  padding-right: 24px;

  ${media.md(css`
    max-width: 60%;
  `)}

  ${media.lg(css`
    max-width: 40%;
  `)}

  ${media.xl(css`
    max-width: 30%;
  `)}

  > button {
    flex: 1;
    height: 42px;
    overflow: hidden;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 18px;
      height: 3px;
      width: 100%;
      background: white;

      ${media.md(css`
        bottom: 25%;
      `)}
    }
  }

  > .carousel__dot--selected {
    &:after {
      opacity: 1;
      background: ${colors.deepBlue};
    }
  }

  > .carousel__dot + .carousel__dot {
    margin-left: 12px;
  }
`

const CarouselWrapper = styled.div`
  height: 100vh;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const SlideImageWrapper = styled.div`
  position: relative;
  height: 90vh;
`

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 25px;
  right: 25px;

  ${media.lg(css`
    top: ${({ theme }) => theme.spacing[1]};
    right: ${({ theme }) => theme.spacing[1]};
  `)}

  @media (max-width: ${breakpoints.md}px) and (orientation: landscape) {
    right: 8px;
  }

  > button {
    position: relative;

    ${media.md(css`
      width: ${({ theme }) => theme.spacing[3]};
      height: ${({ theme }) => theme.spacing[3]};
    `)}
  }
`

export const CarouselModal = ({
  show,
  onHide,
  carousel,
  currentSlide = 0,
  sliderId,
}: CarouselModalProps) => {
  useEffect(() => {
    // Focus the slider when the modal is shown to enable keyboard navigation.
    // Adding a timeout to ensure the slider is rendered before focusing.
    let timeout: NodeJS.Timeout
    if (show && sliderId) {
      timeout = setTimeout(() => {
        // Note: useRef doesn't seem to work with pure-react-carousel, so we use getElementById instead.
        const slider = document.getElementById(sliderId)
        slider?.focus()
      }, 200)
    }
    return () => clearTimeout(timeout)
  }, [show, sliderId])

  return (
    <Modal
      show={show}
      onHide={onHide}
      noWrapper
      closeButton={
        <CloseButtonWrapper>
          <TransparentCloseFab onClick={onHide} />
        </CloseButtonWrapper>
      }
    >
      <CarouselWrapper onClick={onHide}>
        <CarouselProvider
          naturalSlideHeight={carousel[0].fields.file.details.image.height}
          naturalSlideWidth={carousel[0].fields.file.details.image.width}
          totalSlides={carousel.length}
          currentSlide={currentSlide}
          isIntrinsicHeight
        >
          <Slider id={sliderId}>
            {carousel.map((image, index) => (
              <Slide index={index} key={image.fields.title}>
                <SlideImageWrapper>
                  <ContentfulImage
                    image={image}
                    style={{ objectFit: 'contain' }}
                  />
                </SlideImageWrapper>
              </Slide>
            ))}
          </Slider>
          <LeftButton
            onClick={e => {
              e.stopPropagation()
            }}
          >
            <VisuallyHidden>Previous Image</VisuallyHidden>
          </LeftButton>
          <RightButton
            onClick={e => {
              e.stopPropagation()
            }}
          >
            <VisuallyHidden>Next Image</VisuallyHidden>
          </RightButton>
          <Bars />
        </CarouselProvider>
      </CarouselWrapper>
    </Modal>
  )
}
